import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BAR_COLORS = ["#0D49A0"]

const BarChart = ({ labels, datasets }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: datasets.label,
        data: datasets.data,
        backgroundColor: '#0D49A0',
        borderColor: '#0D49A0',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: 'y',
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        display: false,
      },
      title: {
        display: false,
        text: 'Single Group Bar Chart',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return value + '%';
          }
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'category',
        labels: data['labels'],
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
