import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loading from './components/Loading';
import './App.css';


import menu from '../src/assets/menu-icon.svg';
import history from '../src/assets/history.svg';
import feedback from '../src/assets/feedback.svg';
import share from '../src/assets/share.svg';
import usericon from '../src/assets/user.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from './AuthProvider';

function App() {
  const catMenu = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [me, setMe] = useState(null);
  const { isAuthenticated, loginWithRedirect, error, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const api = useApi();

  const closeOpenMenus = (e) => {
    if (isActive && !catMenu.current?.contains(e.target)) {
      setIsActive(false)
    }
  }

  document.addEventListener('mousedown', closeOpenMenus);

  // Function to toggle the class
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  async function getMe() {
    const meResponse = await api.get(`/users/me`);

    setMe(meResponse.data);

    if ((window.location.pathname !== "/accept-tnc" || window.location.pathname !== "/terms-and-conditions") && (meResponse.data.accepted_tos === undefined || meResponse.data.accepted_tos === false)) {
      navigate('/accept-tnc');
    }
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <Loading />
  }

  if (me === null) {
    getMe();
  }

  return (
    <div className="App">
      <div className={isActive ? 'open' : 'overlay'}></div>
      <div className='header' >
        {/* <div className='searchbox'>
              <em><img src={Search} alt="search" /></em>
              <input type="search" id="mySearch" placeholder="Search"></input>
            </div> */}
        <svg>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>

        <div className='header-right'>
          <em onClick={toggleClass}><img src={menu} alt="Menu" /></em>
        </div>
      </div>

      <div ref={catMenu} className={isActive ? 'navigation' : 'navigation-closed'}>
        <ul className="nav-list">
          <li>
            <Link to={`/`} onClick={() => setIsActive(false)}><em><img src={usericon} alt="Account management" /></em> Account management</Link>
          </li>
          <li>
            <Link to={`/surveyhistory`} onClick={() => setIsActive(false)} ><em><img src={history} alt="Survey history" /></em> Survey history</Link>
          </li>
          <li>
            <a href={"mailto:support@avegroup.com"} target="_new"><em><img src={feedback} alt="Provide feedback" /></em> Provide feedback</a>
          </li>
          <li>
            <Link to={`/`} onClick={() => setIsActive(false)}><em><img src={share} alt="Share product" /></em> Share product</Link>
          </li>
        </ul>
        <Link className='btn-default' to={`/`} style={{ textDecoration: 'none' }} onClick={() => setIsActive(false)}>+ New survey</Link>
      </div>
    </div>
  );
}

export default App;
