import { Step, StepLabel, Stepper } from "@mui/material";
import logoWhite from '../assets/logo-white.svg';
import ballot from '../assets/ballot.svg';
import group from '../assets/group.svg';
import reviews from '../assets/reviews.svg';
import shield from '../assets/shield.svg';
import help from '../assets/help.svg';
import logoutIcon from '../assets/logout.svg';
import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

const Sidebar = ({ activeStep, isSurveyShown }) => {
    const { logout } = useAuth0();

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <a
                href={mailto}
            >
                <em><img src={help} alt="help" /></em> Help
            </a>
        );
    };

    return (
        <>
            <div className="stepper-sidebar">
                <div className="logo">
                    <Link to={"/"} style={{ color: "white", textDecoration: "none" }}><img src={logoWhite} /></Link>
                </div>
                {isSurveyShown && (
                    <Stepper activeStep={activeStep}>
                        <Step key="Step 1">
                            <StepLabel><em><img src={group} alt="logo" /></em> Audience</StepLabel>
                        </Step>
                        <Step key="Step 2">
                            <StepLabel><em><img src={ballot} alt="logo" /></em> Segments</StepLabel>
                        </Step>
                        <Step key="Step 3">
                            <StepLabel><em><img src={shield} alt="logo" /></em> Questions</StepLabel>
                        </Step>
                        <Step key="Step 4">
                            <StepLabel><em><img src={reviews} alt="logo" /></em> Review</StepLabel>
                        </Step>
                    </Stepper>
                )}
                {!isSurveyShown && (
                    <Link className='btn-default' to={`/`} style={{ textDecoration: 'none', width: '60%', marginLeft: '5%', justifyContent: 'center' }}>+ New survey</Link>
                )}
                <div className='bottom-block'>
                    <ul>
                        <li>
                            <ButtonMailto mailto={"mailto:support@avegroup.com"} />
                        </li>
                        <li>
                            <a href="#" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}><em><img src={logoutIcon} alt="logout" /></em> Logout</a>
                        </li>
                    </ul>
                </div>
            </div >

        </>
    );
}

export default Sidebar;
