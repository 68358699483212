import React, { useState } from 'react';
import edit from '../assets/edit.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import nodata from '../assets/undraw.svg';

const RightPanel = ({ audienceData, hasAudienceDataNonEmpty,
  segmentData, hasSegmentDataNonEmptyValue,
  questionsData, hasQuestionsDataNonEmptyValue,
  selectSteps, activeTab }) => {


  const segmentSizeTotal = segmentData.reduce((total, field) => {
    return total + (field.segment_size ? parseInt(field.segment_size, 10) : 0);
  }, 0);

  return (
    <div className='right-panel'>
      <h3>Report preview</h3>
      <div className="scrollbar">
        <div>

          <Accordion defaultExpanded={activeTab === 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <h2>Target audience</h2>
            </AccordionSummary>

            <AccordionDetails>
              {
                !hasAudienceDataNonEmpty ? (
                  <><p>Start building your report to see a preview here</p>
                    <div className='no-data-wrapper'>
                      <em><img src={nodata} alt="No data" /></em>
                      <h3>
                        Start adding details<br />to see the report
                      </h3>
                    </div>
                  </>
                ) :
                  (
                    <>
                      <p>Simulated audience group</p>
                      <div className="report-view">
                        <h4>Audience</h4>
                        {audienceData.map(audienceRecord =>
                          audienceRecord.audience.trim() !== '' && (
                            <ul key={audienceRecord.id}>
                              <li>
                                <a>{audienceRecord.audience}<em><img src={edit} onClick={() => selectSteps(0)} alt="edit" /></em></a>
                              </li>
                            </ul>
                          )
                        )}
                      </div>
                    </>
                  )
              }
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={activeTab === 1}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <h2>Segments</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="report-view segments-view">
                {!hasSegmentDataNonEmptyValue && (
                  <p>Add segments to your audience to see more</p>
                )}
                {hasSegmentDataNonEmptyValue && (
                  <>
                    <div className="d-flex heading">
                      <span>Your results will be split out in the following segments</span>
                      <span>Segment size</span>
                    </div>
                    {
                      segmentData.map((x) =>
                        x.segment_name && x.segment_name.trim() !== '' ? (
                          <ul key={x.id}>
                            <li>{x.segment_name}</li>
                            <li>{x.segment_size}</li>
                          </ul>
                        ) : null
                      )
                    }
                    <ul>
                      <li style={{ border: '0' }}></li>
                      <li style={{ backgroundColor: '#E4EEFF', border: '0', color: '#545454' }}>{segmentSizeTotal}</li>
                    </ul>
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={activeTab === 2}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header">
              <h2>Questions</h2>
            </AccordionSummary>

            <AccordionDetails>

              <div className="report-view segments-view">
                {
                  questionsData.map((x, index) => (
                    <div key={index} className='q-block'>
                      {
                        x.question_text && x.question_text.trim() !== ''
                        && ((x.question_type === 'Short Response' || x.question_type === 'Long Response')
                          ? (<p>Q{index + 1}. {x.question_text} ({x.question_type})</p>)
                          : (
                            (x.question_type === 'scale') ? (
                              <>
                                <p>Q{index + 1}. {x.question_text}</p>
                                <div className='q2'>
                                  <ul>
                                    <li>1</li>
                                    <li>2</li>
                                    <li>3</li>
                                    <li>4</li>
                                    <li>5</li>
                                  </ul>
                                  {/* <div className='rating'>
                                    <span>No <br /> importance</span>
                                    <span>Most <br /> important</span>
                                  </div> */}
                                </div>
                              </>
                            )
                              :
                              (
                                <>
                                  <p>
                                    Q{index + 1}. {x.question_text} ({x.question_type})
                                  </p>
                                  {
                                    x.questions && x.questions !== '' &&
                                    <div className='q2 q3'>
                                      <ul>
                                        {

                                          x.questions
                                            .replace(/,\s*$/, '')
                                            .split(',')
                                            .map((item, index) => (
                                              <li key={index}>{item.trim()}</li>
                                            ))
                                        }
                                      </ul>
                                    </div>
                                  }
                                </>
                              )
                          )
                        )
                      }
                    </div>
                  ))
                }
              </div>

            </AccordionDetails>

          </Accordion>

        </div>
      </div>
    </div>
  );
};

export default RightPanel;
