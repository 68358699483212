import Sidebar from './Sidebar'
import StackedColumnChart from './StackedColumnChart';
import BarChart from './BarChart';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import download from '../assets/download-icon.svg';
import { Button } from '@mui/material';
import { useApi } from '../AuthProvider';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import ContentCopy from '@mui/icons-material/ContentCopy';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Grid from '@mui/material/Unstable_Grid2';

const Result = () => {
  const [surveyRunResults, setSurveyRunResults] = useState(null);
  const params = useParams();
  const api = useApi();
  const [copySnackbarOpen, setCopySnackbarOpenOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      var surveyRunsResponse = await api.get(`/surveys/runs/${params.surveyRunId}`);
      setSurveyRunResults(surveyRunsResponse.data);
    }
    if (surveyRunResults === null && api) {
      fetchData();
    }
  }, [api]);

  const downloadSummary = (surveyRunId, summary) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([summary], {
        type: 'text/markdown',
        encoding: 'UTF-8'
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${surveyRunId}.md`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const downloadFile = async (surveyRunId) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download`);
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'text/csv',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunId}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const QuestionResultDetails = ({ question, answers }) => {
    const [numShow, setNumShow] = useState(5);
    const [textShow, setTextShow] = useState('Show more');
    if (question.question_type === 'Short Response' || question.question_type === 'Long Response') {
      return (
        <div>
          {/* if length answers is less than 5 */}
          {
            (answers.length <= 5)
            &&
            answers.map((answer, index) => (
              <div key={index}>
                <p>{answer.answer}</p>
              </div>
            ))
          }
          {/* if length answers is greater than 5 */}
          {
            (answers.length > 5)
            &&
            <div>
              {answers.slice(0, numShow).map((answer, index) => (
                <div key={index}>
                  <p>{answer.answer}</p>
                </div>
              ))}
              <Button
                onClick={() => {
                  if (numShow === 5) {
                    setNumShow(answers.length);
                    setTextShow('Show less');
                  }
                  else {
                    setNumShow(5);
                    setTextShow('Show more');
                  }
                }}
              >{textShow}</Button>
            </div>
          }
        </div>
      );
    } else if (question.question_type === 'Multiple Choice' || question.question_type === 'All Words That Apply') {
      const answerCounts = {};
      const labels = question.options;

      answers.forEach(answer => {
        if (answerCounts[answer.answer.trim()] === undefined) {
          answerCounts[answer.answer.trim()] = 1;
        } else {
          answerCounts[answer.answer.trim()] += 1;
        }
      });

      const totalAnswerCounts = Object.values(answerCounts).reduce((a, b) => a + b, 0);

      const datasets = {
        label: 'Responses',
        data: labels.map(label => (answerCounts[label] / totalAnswerCounts) * 100 || 0),
      }

      return (
        <BarChart data={Object.entries(answerCounts)} datasets={datasets} labels={labels} />
      );
    } else if (question.question_type === 'Numeric Rating') {
      const answerCounts = {};
      const labels = Array.from({ length: 5 }, (_, i) => `${i + 1}`);

      answers.forEach(answer => {
        if (answerCounts[`${answer.answer}`] === undefined) {
          answerCounts[`${answer.answer}`] = 1;
        } else {
          answerCounts[`${answer.answer}`] += 1;
        }
      });

      const totalAnswerCounts = Object.values(answerCounts).reduce((a, b) => a + b, 0);

      const datasets = {
        label: 'Responses',
        data: labels.map(label => (answerCounts[label] / totalAnswerCounts) * 100 || 0),
      }

      return (
        <BarChart data={Object.entries(answerCounts)} datasets={datasets} labels={labels} />
      );
    }
  }

  const QuestionAudienceSegmentResults = ({ question, segment, answers }) => {
    // this goes through the answers every question, so it'll start going REALLY slow if there are a lot of answers
    const segmentAnswers = answers.filter(answer => answer.question_id == question.question_id && answer.audience_segment_id == segment.audience_segment_id);
    return (
      <div>
        <h4>{segment.segment_name}</h4>
        <QuestionResultDetails question={question} answers={segmentAnswers} />
      </div>
    );
  }

  const QuestionResult = ({ questionNo, question, segments, answers }) => {
    return (
      <div>
        <h4>Question {questionNo}</h4>
        <p>{question.question_text}</p>
        {segments.map((segment, index) => (
          <QuestionAudienceSegmentResults key={index} question={question} segment={segment} answers={answers} />
        ))}
      </div>
    );
  }

  const SurveySummary = ({ summary }) => {
    return (
      <Markdown>{summary}</Markdown>
    );
  }

  const QuestionResults = ({ questions, segments, answers }) => {
    return (
      <>
        {questions.map((question, index) => (
          <QuestionResult key={index} questionNo={index + 1} question={question} segments={segments} answers={answers} />
        ))}
      </>
    );
  }

  const copySummary = () => {
    navigator.clipboard.writeText(surveyRunResults.summary);

    setCopySnackbarOpenOpen(true);
  }

  if (surveyRunResults === null) {
    return <div>Loading...</div>;
  }

  return (
    <Box className="dashboard">
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={5000}
        onClose={(event, reason) => {
          setCopySnackbarOpenOpen(false);
        }}
        message="Copied to clipboard successfully."
        className={'copy-snackbar'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Box sx={{ width: '100%' }}>
        <Sidebar isSurveyShown={false}></Sidebar>
        <Box className='left-panel' style={{ width: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <h3>Results & Insights</h3>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <a onClick={() => copySummary()} style={{
                color: '#0D49A0',
                textDecoration: 'none',
                cursor: 'pointer'
              }}><ContentCopy style={{ height: "17px" }} /> Copy Summary</a>
            </Grid>
          </Grid>
          <h2>Key insights</h2>
          <SurveySummary summary={surveyRunResults.summary} />
          <div className='review-block'>
            <div className='result-header'>
              <div className='tabs'></div>
              <a onClick={() => downloadFile(params.surveyRunId)} className='download'><em><img src={download} alt="download" /></em>Download Results</a>
              {/* <a onClick={() => downloadSummary(params.surveyRunId, surveyRunResults.summary)} className='download'><em><img src={download} alt="download" /></em>Download Summary</a> */}

            </div>
            <div className='results-row'>
              <h2>Audience</h2>

              <div className='results-col'>
                <h4>Insights </h4>
                <ul>
                  <li>
                    Total responses
                    <h2>{surveyRunResults.total_respondents || 0}</h2>
                  </li>
                  <li>
                    Total questions
                    <h2>{surveyRunResults.questions.length}</h2>
                  </li>
                  <li>
                    Audience segments
                    <h2>{surveyRunResults.audiences[0].segments.length}</h2>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className='results-row'>
              <h2>Segments </h2>
              <div className='chart-block'>
                <StackedColumnChart />
              </div>
            </div> */}

            <div className='results-row'>
              <h2>Questions</h2>

              <QuestionResults questions={surveyRunResults.questions} segments={surveyRunResults.audiences[0].segments} answers={surveyRunResults.answers} />

            </div>

          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Result;
