import Sidebar from './Sidebar'
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CachedIcon from '@mui/icons-material/Cached';
import download from '../assets/download-icon.svg';
import { useApi } from '../AuthProvider';
import EditIcon from '@mui/icons-material/Edit';

const SurveyHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [surveyRuns, setSurveyRuns] = useState([]);
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      var surveyResponse = await api.get(`/surveys/runs`);
      setIsLoading(false);
      setSurveyRuns(surveyResponse.data);
    }
    if (api) fetchData();
  }, [api]);

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     const allComplete = await updateRunStatus();

  //     if (allComplete === true) {
  //       clearInterval(interval);
  //     }
  //   }, 5000);
  // }, []);

  const updateRunStatus = async () => {
    setSurveyRuns([]);
    setIsLoading(true);
    var surveyResponse = await api.get(`/surveys/runs`);
    setIsLoading(false);
    setSurveyRuns(surveyResponse.data);
  }

  const reRunSurvey = async (surveyId) => {
    var confirmSurveyResponse = await api.get(`/surveys/${surveyId}/run`);
    if (confirmSurveyResponse) {
      var jobData = confirmSurveyResponse.data;
      console.log('Survey run id:', jobData.survey_run_id);
    }
  }

  const downloadSummary = async (surveyRunId) => {
    // Get surveyRunResponse
    const surveyRunResponse = await api.get(`/surveys/runs/${surveyRunId}`);
    if (surveyRunResponse) {
      const surveyRunResults = surveyRunResponse.data;
      const summary = surveyRunResults.summary;

      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([summary], {
          type: 'text/markdown',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunId}.txt`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const downloadFile = async (surveyRunId) => {
    var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download`);
    if (downloadResponse) {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([downloadResponse.data], {
          type: 'text/csv',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${surveyRunId}.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  function SurveyActionButtonsColumn({ surveyRunId, status, surveyId }) {
    if (status !== 'complete') {
      return <td></td>
    }

    return (
      <td>
        <Button onClick={() => navigate(`/result/${surveyRunId}`)} variant="outlined" className="crowdwave-blue-light">View results</Button>
        <a onClick={() => downloadFile(surveyRunId)} className='download active'><em><img src={download} alt="download" /></em>Download CSV</a>
        {/* <a onClick={() => downloadSummary(surveyRunId, 'Summary')} className='download active'><em><img src={download} alt="download" /></em>Download Summary</a> */}
        {/* <a onClick={() => reRunSurvey(surveyId)} className='re-run active'><em>Re-run</em></a> */}
      </td>
    )
  }

  function SurveyRunTableRow({ surveyRunId, surveyId, status, name, date, segments, questions }) {
    return (
      <tr>
        <td className="status"><span className={status}>{status}</span><br />{name} <a onClick={() => navigate(`/create-survey/${surveyId}`)}><EditIcon style={{ fontSize: "medium", cursor: "pointer" }} /></a></td>
        <td>{date}</td>
        <td>{segments}</td>
        <td>{questions}</td>
        <SurveyActionButtonsColumn surveyRunId={surveyRunId} status={status} surveyId={surveyId} />
      </tr>
    )
  };

  function TableBody({ surveyRuns }) {
    if (isLoading) {
      return <tbody><tr><td colSpan={5} style={{ "textAlign": "center" }}>Loading...</td></tr></tbody >
    }

    return (
      <tbody>
        {surveyRuns.map((surveyRun) => {
          return <SurveyRunTableRow key={surveyRun.survey_run_id} surveyRunId={surveyRun.survey_run_id} surveyId={surveyRun.survey_id} status={surveyRun.status} name={surveyRun.name} date={surveyRun.created_at_formatted} segments={surveyRun.number_of_segments} questions={surveyRun.number_of_questions} />
        })}
      </tbody>
    )
  }

  return (
    <div className="dashboard">
      <Box sx={{ width: '100%' }}>

        <Sidebar activeStep={0} isSurveyShown={false}></Sidebar>

        <div className='left-panel' style={{ width: 'auto' }}>
          <h2 style={{ marginBottom: '35px', marginTop: '0' }}>Survey history</h2>
          <div style={{ textAlign: 'right' }}><Button onClick={() => updateRunStatus()} variant="contained" startIcon={<CachedIcon />} style={{ marginBottom: "8px" }} className="crowdwave-blue">Refresh with new results</Button></div>
          <table className='user-surveys-table' style={{ width: '100%', border: '0' }}>
            <thead>
              <tr>
                <th>name</th>
                <th>date</th>
                <th>segments</th>
                <th>Questions</th>
                <th>Results</th>
              </tr>
            </thead>
            <TableBody surveyRuns={surveyRuns} />
          </table>
        </div>

      </Box>
    </div>
  );
};

export default SurveyHistory;
