import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const Step3Component = ({
  audienceData,
  segmentData,
  questionsData,
  hasSegmentDataNonEmptyValue,
  selectSteps,
  errorText
}) => {

  const segmentSizeTotal = segmentData.reduce((total, field) => {
    return total + (field.segment_size ? parseInt(field.segment_size, 10) : 0);
  }, 0);

  const hasQuestionFieldNonEmptyValue = questionsData.some(textField => textField.question_text !== '');

  const ErrorAlert = ({ errorText }) => {
    if (!errorText) {
      return;
    }

    return (
      <Alert severity="error" style={{ marginBottom: "20px", borderRadius: "20px" }}>
        <AlertTitle>Error</AlertTitle>
        {errorText}
      </Alert>
    );
  }

  return (
    <>
      <ErrorAlert errorText={errorText} />

      <div className='content'>
        <div className='left-panel' style={{ width: '100%', paddingRight: '10%', marginBottom: '10px' }}>
          <h3>Report preview</h3>
          <h2>Your report is ready to run</h2>
          <p>Please review your report below before running </p>

          <div className='review-block'>
            <div className='review-col'>
              <h4>Target audience</h4>
              <a style={{ cursor: 'pointer' }} onClick={() => selectSteps(0)} >Edit</a>
            </div>
            {
              audienceData.map(audienceRecord =>
                audienceRecord.audience.trim() !== '' && (
                  <div className='review-col' key={audienceRecord.id}>
                    <span>{audienceRecord.audience}</span>
                  </div>
                )
              )
            }
            <div className='review-col  col-3'>
              <h4>Segments </h4>
              <h4>Responses</h4>
              <a style={{ cursor: 'pointer' }} onClick={() => selectSteps(1)} > Edit</a>
            </div>
            {
              hasSegmentDataNonEmptyValue && (
                <>
                  {segmentData.map((x) =>
                    x.segment_name && x.segment_name !== '' ? (
                      <div className='review-col col-3' key={x.id}>
                        <span>{x.segment_name}</span>
                        <span>{x.segment_size}</span>
                        <span></span>
                      </div>
                    ) : null
                  )}
                  <div className='review-col col-3 last-child'>
                    <span></span>
                    <span><strong>{segmentSizeTotal}</strong></span>
                    <span></span>
                  </div>
                </>
              )
            }

            <div className='review-col'>
              <h4>Total questions </h4>
              <a style={{ cursor: 'pointer' }} onClick={() => selectSteps(2)} >Edit</a>
            </div>

            <div className='review-col'>
              <span>{hasQuestionFieldNonEmptyValue ? questionsData.length : 0}</span>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Step3Component;
