import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedColumnChart = () => {
    const data = {
        labels: Array.from({ length: 60 }, (_, i) => `${i + 1}`),
        datasets: [
            {
                label: 'Group 1',
                data: Array.from({ length: 60 }, () => Math.floor(Math.random() * 20)),
                backgroundColor: '#0D49A0',
                borderColor: '#0D49A0',
                borderWidth: 1,
            },
            {
                label: 'Group 2',
                data: Array.from({ length: 60 }, () => Math.floor(Math.random() * 40)),
                backgroundColor: '#5CC8BE',
                borderColor: '#5CC8BE',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'end'
            },
            title: {
                display: true,
                text: 'Name',
                position: 'top',
                align: 'start',
                padding: {
                  bottom: 30,
                  top: 20,
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                max: 10,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                beginAtZero: true,
                max: 60,
                grid: {
                    display: false,
                },
            }
        },
    };

    return (
        <div className="chart-container">
            <Bar data={data} options={options} />
        </div>
    );
};

export default StackedColumnChart;
