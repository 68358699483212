import { Box } from '@mui/material';
import React from 'react';

const Loading = () => {
    return (
        <Box style={{ textAlign: "center" }}>
            Loading...
        </Box>
    );
};

export default Loading;