import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../AuthProvider';

const TnCModal = () => {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const api = useApi();

    const handleAgree = async () => {
        const acceptResponse = await api.post(`/user/accept-tos`);

        // this is a bit fragile, but we want to make sure the user has accepted the TOS and that
        // it was recorded in the backend before we navigate away
        if (acceptResponse.data.detail !== undefined && acceptResponse.data.detail === 'TOS accepted') {
            navigate('/');
        };
    };

    const handleDisagree = () => {
        logout({ returnTo: window.location.origin });
    };

    return (
        <React.Fragment>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Welcome to Crowdwave!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By clicking Agree, you agree to the <a href="/terms-and-conditions" target="_blank">Crowdwave Terms of Service</a>.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisagree}>Disagree</Button>
                    <Button onClick={handleAgree}>Agree</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default TnCModal;