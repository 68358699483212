import RightPanel from './RightPanel'
import Step3Component from './Step3'
import Sidebar from './Sidebar'
import { useApi } from '../AuthProvider';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import close from '../assets/close.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import serching from '../assets/serching.svg';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from './Loading';
import upload from '../assets/upload-icon.svg';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import * as Sentry from "@sentry/react";

const steps = ['Audience', 'Segments', 'Questions', 'Questions1'];

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#202020',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const CreateSurvey = ({ existingSurveyId }) => {
  const [errorText, setErrorText] = useState('');
  const [loadedExistingSurvey, setLoadedExistingSurvey] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  let surveyId = null;
  let surveyRunId = null;
  let audienceId = null;
  const questionsList = [
    { value: "", label: "Short Response" },
    { value: "long", label: "Long Response" },
    { value: "multiple", label: "Multiple Choice" },
    { value: "chooseAll", label: "All Words That Apply" },
    { value: "scale", label: "Numeric Rating" }
  ];

  const getQuestionByValue = (value) => {
    const item = questionsList.find(item => item.value === value);
    return item ? item.label : "Value not found";
  };

  const addSegmentLimt = React.useState(20);
  const addQuestionsLimit = React.useState(50);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  //spte 1 related changes   
  const [isShowAudienceError, setIsShowAudienceError] = useState(false);
  const [audienceData, setAudienceData] = useState([
    {
      id: 1,
      survey_id: "",
      audience: "",
      audience_id: ""
    }
  ]);
  const hasAudienceDataNonEmpty = audienceData.some(textField => textField.audience.trim() !== '');
  const addTextField = () => {
    setAudienceData([...audienceData, { id: audienceData.length + 1, audience: '', survey_id: "", audience_id: "" }]);
  };
  const handleChange = (id, event) => {
    const newAudienceRecord = audienceData.map(audData => {
      if (audData.id === id) {
        return { ...audData, audience: event.target.value, survey_id: audData.survey_id, audience_id: audData.audience_id };
      }
      return audData;
    });
    setAudienceData(newAudienceRecord);

    if (event.target.value)
      setIsShowAudienceError(false);
  };
  const handleRemoveValidationChange = (id, event) => {
    if (event.target.value)
      setIsShowAudienceError(false);
  };
  //step 1 end

  //step 2 related changes 
  const [segmentData, setSegmentData] = useState([
    {
      id: 1,
      audience_id: "",
      segment_name: "",
      segment_size: '',
      audience_segment_id: '',
      has_name_error: false,
      has_size_error: false
    }
  ]);
  const [isSegmentNameError, setIsSegmentNameError] = useState(false);
  const [isSegmentSizeError, setIsSegmentSizesError] = useState(false);
  const hasSegmentDataNonEmptyValue = segmentData.some(seg => seg.segment_name && seg.segment_name.trim() !== '' && seg.segment_size !== '');

  const addSegmentField = () => {
    if (segmentData.length !== addSegmentLimt) {
      setSegmentData([...segmentData, { id: segmentData.length + 1, segment_name: '', segment_size: '', audience_id: "", audience_segment_id: '', has_name_error: false, has_size_error: false }]);
    }
  };

  const removeSegmentField = (id) => {
    let rowsInput = [...segmentData];
    let index = rowsInput.findIndex(x => x.id === id);
    rowsInput.splice(index, 1);
    setSegmentData(rowsInput);
  };

  const handleChangeSegment = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...segmentData];
    rowsInput[index][name] = value;
    setSegmentData(rowsInput);
    if (value)
      setIsSegmentNameError(false);
  }
  // TODO: this validation should be handled by an input type="number" field not by JS
  const handleSizeChange = (index, evnt) => {
    const { name, value } = evnt.target;
    if ((/^\d*$/.test(value) && value >= 1 && value <= 1000) || value === '') {
      const rowsInput = [...segmentData];
      rowsInput[index][name] = value;
      setSegmentData(rowsInput);
    }
  };
  const handleRemoveValidationForSegmentChange = (id, evnt) => {
    const { name, value } = evnt.target;
    if (name === 'segment_name' && value)
      setIsSegmentNameError(false);
    if (name === 'segment_size' && value)
      setIsSegmentSizesError(false);
  };
  //step 2 end

  //step 3 related changes  
  const [isQuestionTextError, setIsQuestionTextError] = useState(false);
  const [questionsData, setQuestionsData] = useState([{
    id: 1,
    survey_id: "",
    question_text: "",
    question_type: "Short Response",
    sort_order: 1,
    options: [],
    rankings: ['', '', '', '', ''],
    questions: '',
    question_id: "",
    has_question_error: false
  }]);

  const hasQuestionsDataNonEmptyValue = questionsData.some(textField => textField.question_text !== '' && textField.question_type !== '');
  const addQuestionTextFields = () => {
    if (questionsData.length !== addQuestionsLimit) {
      setQuestionsData(
        [...questionsData,
        {
          id: questionsData.length + 1,
          question_text: '',
          question_type: 'Short Response',
          survey_id: '',
          sort_order: questionsData.length + 1,
          options: [],
          rankings: ['', '', '', '', ''],
          questions: '',
          question_id: "",
          has_question_error: false
        }
        ]);
    }
  };

  const handleQuestionTextFieldChange = (id, event) => {
    const newQuestions = questionsData.map(question => {
      if (question.id === id) {
        return { ...question, question_text: event.target.value };
      }
      return question;
    });
    setQuestionsData(newQuestions);
    setIsQuestionTextError(false);
  };

  const handleQuestionDrpFieldChange = (id, event) => {
    const newQuestions = questionsData.map(question => {
      if (question.id === id) {
        if (event.target.value !== 'multiple' && event.target.value !== 'chooseAll') {
          question.questions = '';
        }
        return { ...question, question_type: event.target.value };
      }
      return question;
    });
    setQuestionsData(newQuestions);
  };

  const handleQuestionsFieldChange = (id, event) => {
    const newQuestions = questionsData.map(question => {
      if (question.id === id) {
        return { ...question, questions: event.target.value };
      }
      return question;
    });
    setQuestionsData(newQuestions);
  };

  const handleRemoveValidationForQuestionsChange = (id, evnt) => {
    if (evnt.target.value)
      setIsQuestionTextError(false);
  };

  //step 3 end
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    var isError = false;

    // start with no errors
    setErrorText('');

    // Fixed issue with calling createAudience() multiple times
    // by seperating coniditional check for activeStep === 0
    // and checking if audienceData is not empty
    if (activeStep === 0) {
      // audience check null or not 
      if (!hasAudienceDataNonEmpty) {
        setIsShowAudienceError(true);
        isError = true;
      }
    }

    // segment check if null or not
    if (activeStep === 1) {
      const segmentsWithBlankName = segmentData.some(seg => seg.segment_name.trim() === '');
      const segmentsWithBlankSize = segmentData.some(seg => seg.segment_size === '');

      if (segmentsWithBlankSize || segmentsWithBlankName) {
        setSegmentData(segmentData.map(seg => {
          return {
            ...seg,
            has_name_error: seg.segment_name.trim() === '',
            has_size_error: seg.segment_size === ''
          }
        }));
        return;
      }
    }

    // question check if null or not
    if (activeStep === 2) {
      const questionsWithNoQuestion = questionsData.some(ques => ques.question_text.trim() === '');

      if (questionsData.length === 0) {
        setErrorText('Please add at least one question to proceed');
        return;
      }

      if (questionsWithNoQuestion) {
        setQuestionsData(questionsData.map(ques => {
          return {
            ...ques,
            has_question_error: ques.question_text.trim() === ''
          }
        }));
        return;
      }
    }

    // if nt error it will redirect to next page
    if (!isError) {
      if (activeStep === 0) {
        // createSurveyRecord();
      } else if (activeStep === 1) {
        // saveSegments()
      } else if (activeStep === 2) {
        // saveQuestions();
      } else if (activeStep === 3) {
        setActiveStep(4);

        let stepSuccess = false;

        stepSuccess = await createAudience();

        if (stepSuccess !== true) {
          setErrorText('Error creating audience');
          setActiveStep(3);
          return;
        }

        stepSuccess = await saveSegments();

        if (stepSuccess !== true) {
          setErrorText('Error creating segments');
          setActiveStep(3);
          return;
        }

        stepSuccess = await saveQuestions();

        if (stepSuccess !== true) {
          setErrorText('Error creating questions');
          setActiveStep(3);
          return;
        }

        stepSuccess = await runSurvey();

        if (stepSuccess !== true) {
          setErrorText('Error running survey');
          setActiveStep(3);
          return;
        }

        return;
      }

      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const selectSteps = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const createSurvey = async (surveyName) => {
    if (surveyId !== null) {
      return surveyId;
    }

    try {
      const response = await api.post('/surveys/', {
        name: surveyName.substring(0, 255), // the survey name is the name of the first audience
      });

      return response.data.survey_id;

    } catch (error) {
      console.error('Error making POST request', error);
      setErrorText(error.message || 'Error creating survey');
    }
  };

  const createAudience = async () => {
    if (surveyId === null) {
      surveyId = await createSurvey(audienceData[0].audience);
    }

    try {
      const response = await api.post('/audiences/', {
        survey_id: surveyId,
        audience: audienceData[0].audience,
      });

      if (response && response.status !== 200) {
        console.error('Error making POST request', response.data);
        setErrorText(response.data.message || 'Error creating audience');
        return false;
      }

      audienceId = response.data.audience_id;
      setAudienceData([{ ...audienceData[0], audience_id: response.data.audience_id }]);
    } catch (error) {
      console.error('Error making POST request', error);
      setErrorText(error || 'Error creating audience');
      return false;
    }

    return true;
  };

  const saveSegments = async () => {
    if (hasSegmentDataNonEmptyValue) {
      try {
        const createSegData = segmentData
          .filter(seg => !seg.audience_segment_id || seg.audience_segment_id.length === 0)
          .filter(seg => seg.segment_name && seg.segment_name.length > 0 && seg.segment_size && parseInt(seg.segment_size) > 0)
          .map(seg => ({
            "segment_name": seg.segment_name,
            "segment_size": parseInt(seg.segment_size)
          }));

        if (createSegData.length === 0) {
          Sentry.captureMessage(`No segments to create from: ${JSON.stringify(segmentData)}`);
          console.error('No segments to create from:', segmentData);
          return false;
        }

        const createSegResponse = await api.post('/audience_segments/bulk', {
          audience_id: audienceId,
          segments: createSegData
        });

        if (createSegResponse && createSegResponse.status !== 200) {
          console.error('Error making POST request', createSegResponse.data);
          setErrorText(createSegResponse.data.message || 'Error creating segments');
          return false;
        }

        if (createSegResponse && createSegResponse.statusText === "OK") {
          var segData = createSegResponse.data;
          const updatedData = segmentData.map(item => {
            const updatedItem = segData.find(seg => seg.segment_name === item.segment_name && seg.segment_size === parseInt(item.segment_size));
            if (updatedItem) {
              return { ...item, audience_segment_id: updatedItem.audience_segment_id };
            }
            return item;
          });

          setSegmentData(updatedData);
        }

      } catch (error) {
        if (error.response) {
          console.error('Server error:', error.response.status, error.response.data);
          setErrorText(error.response.data.message);
        } else if (error.request) {
          console.error('Network error:', error.request);
          setErrorText(error.request);
        } else {
          console.error('Error:', error.message);
          setErrorText(error.message);
        }
        return false;
      }
    }

    return true;
  }

  const saveQuestions = async () => {
    if (hasAudienceDataNonEmpty) {
      try {
        const createQuesData = questionsData
          .filter(que => !que.question_id)
          .filter(que => que.question_text && que.question_text.length > 0)
          .map(ques => (
            {
              "question_text": ques.question_text,
              "question_type": ques.question_type,
              "sort_order": ques.sort_order,
              "options": ques.questions ? ques.questions.split(',') : [""]
            }
          ));

        if (createQuesData.length === 0) {
          Sentry.captureMessage(`No questions to create from: ${JSON.stringify(questionsData)}`);
          console.error('No questions to create from:', questionsData);
          return false;
        }

        const createQuesResponse = await api.post('/questions/bulk', {
          survey_id: surveyId,
          questions: createQuesData
        });

        if (createQuesResponse && createQuesResponse.statusText === "OK") {
          var queData = createQuesResponse.data;
          const updatedData = questionsData.map(item => {
            const updatedItem = queData.find(que => que.question_text === item.question_text);
            if (updatedItem) {
              return { ...item, question_id: updatedItem.question_id };
            }
            return item;
          });
          setQuestionsData(updatedData);
        }

      } catch (error) {
        if (error.response) {
          console.error('Server error:', error.response.status, error.response.data);
          setErrorText(error.response.data.message);
          return false;
        } else if (error.request) {
          console.error('Network error:', error.request);
          setErrorText(error.request);
          return false;
        } else {
          console.error('Error:', error.message);
          setErrorText(error.message);
          return false;
        }
      }
    }

    return true;
  }

  const runSurvey = async () => {
    const confirmSurveyResponse = await api.get(`/surveys/${surveyId}/run`);

    if (confirmSurveyResponse) {
      const jobData = confirmSurveyResponse.data;
      surveyRunId = jobData.survey_run_id;
      surveyId = null;
      audienceId = null;
    }

    navigate('/surveyhistory');
  }

  const handleRemoveQuestion = (index) => {
    setQuestionsData(questionsData.filter((_, i) => i !== index));
  }

  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = (e.target.result);
      const questions = text.split(/\r?\n/).map((line, index) => {
        return {
          id: index + 1,
          question_text: line,
          value: line,
          question_type: '',
          sort_order: index + 1,
          options: [],
          rankings: ['', '', '', '', ''],
          questions: '',
          question_id: "",
          has_question_error: false
        };
      });
      setQuestionsData(questions);
    };
    reader.readAsText(file);
  }

  const ErrorAlert = ({ errorText }) => {
    if (!errorText || errorText === '') {
      return;
    }

    return (
      <Alert severity="error" style={{ marginBottom: "20px", borderRadius: "20px" }}>
        <AlertTitle>Error</AlertTitle>
        {errorText}
      </Alert>
    );
  }

  const loadExistingSurvey = async () => {
    if (surveyId !== null) return;

    const response = await api.get(`/surveys/${existingSurveyId}`);

    if (response && response.status === 200) {
      const survey = response.data;
      setAudienceData([{ ...audienceData[0], audience: survey.name }]);
      audienceId = survey.audience_id;

      setSegmentData(survey.audiences[0].segments.map((seg, index) => {
        return {
          id: index + 1,
          audience_id: survey.audience_id,
          segment_name: seg.segment_name,
          segment_size: `${seg.segment_size}`,
          audience_segment_id: "",
          has_name_error: false,
          has_size_error: false
        }
      }));

      setQuestionsData(survey.questions.map((ques, index) => {
        return {
          id: index + 1,
          survey_id: "",
          question_text: ques.question_text,
          question_type: ques.question_type,
          sort_order: ques.sort_order,
          options: ques.options,
          rankings: ['', '', '', '', ''],
          questions: ques.options.join(', '),
          question_id: "",
          has_question_error: false
        }
      }));

      setLoadedExistingSurvey(true);
    } else {
      setErrorText('Error loading existing survey');
      surveyId = 0;
    }

  }

  if (existingSurveyId !== null && loadedExistingSurvey === false) {
    loadExistingSurvey();
    return <Loading />;
  }

  return (
    <div className="dashboard">
      <Box sx={{ width: '100%' }}>
        <Sidebar activeStep={activeStep} isSurveyShown={true}></Sidebar>
        <div className='dashboard-right'>
          <ErrorAlert errorText={errorText} />
          {
            activeStep === steps.length ? (
              <div className='left-panel' style={{ width: 'auto' }}>
                <React.Fragment>
                  <div className='no-survey'>
                    <em><img src={serching} alt="serching" /></em>
                    <h2 sx={{ mt: 2, mb: 1 }}>
                      Please wait...
                      <span>simulating your survey</span>
                    </h2>
                  </div>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {/* <Button onClick={handleReset}>Reset</Button> */}
                  </Box>
                </React.Fragment>
              </div>
            )
              :
              (
                <React.Fragment>

                  <div style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                    <Grid container>

                      <Grid xs={12} md={12} lg={8} >
                        <Container className="left-panel">
                          <h3>Set up</h3>
                          <h2>Who is your audience?</h2>
                          <p>Let's define the audience you want to conduct your research on.</p>
                          <div className='content-top scrollbar'>
                            <label className='label'>Audience
                              <CustomTooltip placement="right"
                                title={
                                  <React.Fragment>
                                    Define any group you want to target.
                                    <br />You can be specific or general depending on
                                    <br />your objective.
                                    <br />
                                    For example, 'Soda drinkers in America' or
                                    <br />'Car enthusiasts in California.'
                                  </React.Fragment>
                                } >
                                <em>i</em>
                              </CustomTooltip>
                            </label>
                            <Box className="textBox" sx={{ mt: 2 }}>
                              {
                                audienceData.map((data, index) => {
                                  const { id, audience } = data;
                                  return (
                                    <Box className="textField-box" key={id}>
                                      <input
                                        tabIndex="1" type="text"
                                        placeholder={isShowAudienceError && index === 0 && !hasAudienceDataNonEmpty ? 'Please add your audience to proceed ' : 'Type here'}
                                        name='audience'
                                        value={audience}
                                        className={isShowAudienceError && index === 0 && !hasAudienceDataNonEmpty ? 'has-error' : ''}
                                        onChange={(e) => handleChange(index + 1, e)}
                                        onKeyPress={(e) => handleRemoveValidationChange(index + 1, e)}
                                      />
                                      {/* <span className="add-more-close" onClick={() => removeTextField(id)}> &#x2715; </span> */}
                                    </Box>
                                  );
                                })
                              }
                              <Button onClick={addTextField} variant="contained" startIcon={<AddIcon />} style={{ display: 'none' }}>
                                + Add one more
                                <span>(Optional)</span>
                              </Button>
                            </Box>
                          </div>
                        </Container>
                      </Grid>
                      <Grid xs={12} md={12} lg={4}>
                        <RightPanel
                          audienceData={audienceData}
                          hasAudienceDataNonEmpty={hasAudienceDataNonEmpty}
                          segmentData={segmentData}
                          hasSegmentDataNonEmptyValue={hasSegmentDataNonEmptyValue}
                          questionsData={questionsData}
                          hasQuestionsDataNonEmptyValue={hasQuestionsDataNonEmptyValue}
                          selectSteps={selectSteps} activeTab={0} />
                      </Grid>
                    </Grid>
                  </div>

                  <div className='step-sec' style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                    <Grid container>

                      <Grid item xs={12} md={12} lg={8} >
                        <Container className="left-panel">
                          <h3>Set up</h3>
                          <h2>Segments</h2>
                          <p>Create segments of your audience. These can be based on things like purchase or use frequency, demographics, psychographics, behaviors, or unique preferences. Each segment can be as specific as you like.</p>
                          <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={6} lg={7} className="label">
                              Segment
                              <CustomTooltip placement="right"
                                title={
                                  <React.Fragment>
                                    Be as detailed as possible when describing
                                    <br /> your segment. You can add multiple
                                    <br />descriptors per field if needed.
                                    <br />E.g People who drink Coke 1+ times per
                                    <br />week AND work out 2+ times per week.
                                  </React.Fragment>
                                }>
                                <em className="info">i</em>
                              </CustomTooltip>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2} className="label">Segment size</Grid>
                            <Grid item xs={12} md={3} lg={3}></Grid>

                            {
                              segmentData.map((data, index) => {
                                const { segment_name, segment_size, id, has_name_error, has_size_error } = data;
                                return (
                                  <React.Fragment key={id}>
                                    <Grid item xs={12} md={6} lg={7} key={id}>
                                      <input
                                        type="text"
                                        name="segment_name"
                                        placeholder={has_name_error ? 'Please add segment to proceed' : 'Type here'}
                                        tabIndex={index * 2 + 1}
                                        value={segment_name}
                                        onChange={(e) => handleChangeSegment(index, e)}
                                        className={has_name_error ? 'has-error' : ''}
                                        onKeyDown={(e) => handleRemoveValidationForSegmentChange(index, e)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={2}>
                                      <input
                                        type="text"
                                        name="segment_size"
                                        id="mySearch"
                                        tabIndex={index * 2 + 2}
                                        placeholder="e.g. 50"
                                        value={segment_size}
                                        className={has_size_error ? 'has-error' : ''}
                                        onChange={(e) => handleSizeChange(index, e)}
                                        onKeyDown={(e) => handleRemoveValidationForSegmentChange(index, e)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<RemoveIcon />}
                                        onClick={() => removeSegmentField(id)}
                                      >Remove</Button>
                                    </Grid>
                                  </React.Fragment>
                                );
                              })
                            }
                          </Grid>
                          {
                            segmentData.length !== addSegmentLimt &&
                            <Button
                              onClick={addSegmentField}
                              variant="contained"
                              startIcon={<AddIcon />}
                              tabIndex={segmentData.length * 2 + 1}
                              style={{ marginTop: '20px' }}
                              className="crowdwave-blue"
                            >
                              Add segment
                            </Button>
                          }
                          {/* <div className="improve-btn" tabIndex={segmentData.length * 2 + 2}>
                              <em><img src={sparkles} alt="sparkles" /></em> Improve with AI
                            </div> */}


                        </Container>
                      </Grid>
                      <Grid xs={12} md={12} lg={4}>
                        <RightPanel
                          audienceData={audienceData}
                          hasAudienceDataNonEmpty={hasAudienceDataNonEmpty}
                          segmentData={segmentData}
                          hasSegmentDataNonEmptyValue={hasSegmentDataNonEmptyValue}
                          questionsData={questionsData}
                          hasQuestionsDataNonEmptyValue={hasQuestionsDataNonEmptyValue}
                          selectSteps={selectSteps} activeTab={1} />
                      </Grid>
                    </Grid>
                  </div>

                  <div style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                    <Grid container>

                      <Grid xs={12} md={12} lg={8} >
                        <Container className="left-panel">
                          <h3>Set up</h3>
                          <h2>Questions</h2>
                          <p>Add your questions here. <br /></p>

                          <div>
                            <Box className={`my-component ${isActive ? 'active' : ''}`}>
                              <Button style={{ display: isActive ? 'none' : 'inline-flex' }} onClick={handleToggle} variant="contained" startIcon={<CloudUploadIcon />} className="crowdwave-blue-light">
                                Bulk Upload Questions
                              </Button>
                              <Box className='upload-block'>
                                <Button component="label" role={undefined} variant="contained">
                                  <em><img src={upload} alt="upload" /></em> Click to Upload CSV
                                  <VisuallyHiddenInput type="file" accept=".csv" onChange={(e) => handleUpload(e)} />
                                </Button>
                                <em className='close-icon' onClick={handleToggle}> <img src={close} alt="upload" /></em>
                              </Box>
                              <p style={{ display: isActive ? 'block' : 'none' }}>Upload a CSV file with one question per line.</p>
                            </Box>

                            <Box className="" sx={{ mt: 2 }}>
                              {
                                questionsData.map((questionRecord, index) => (
                                  <Box className="textField-box" key={index}>

                                    <Box>
                                      <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                                        <label style={{ fontSize: '24px', color: '#161616', fontWeight: '500' }}
                                          className='label'><span>Question {index + 1}</span>
                                        </label>

                                        <Button variant="outlined" color="error" onClick={() => handleRemoveQuestion(index)} startIcon={<RemoveIcon />}>Remove</Button>
                                      </Stack>
                                    </Box>

                                    <Box className='question-bontent' sx={{ mt: 2 }}>
                                      <textarea placeholder={questionRecord.has_question_error ? 'Please add question to proceed' : 'Type here'}
                                        value={questionRecord.question_text}
                                        onChange={(event) => handleQuestionTextFieldChange(questionRecord.id, event)}
                                        tabIndex={(index * 2) + 1}
                                        className={questionRecord.has_question_error ? 'has-error' : ''}
                                        onKeyDown={(e) => handleRemoveValidationForQuestionsChange(index, e)}
                                      >
                                      </textarea>
                                      {/* <div tabIndex={(index * 2) + 2} className="improve-btn"><em><img src={sparkles} alt="sparkles" /></em> Improve with AI </div> */}
                                    </Box>

                                    <Box className={`selectbox ${isActive ? 'active' : ''}`}>
                                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                                        <Select tabIndex={(index * 2) + 3}
                                          value={questionRecord.question_type}
                                          onChange={(event) => handleQuestionDrpFieldChange(questionRecord.id, event)}
                                          displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                                          <MenuItem value="Short Response">Short Response</MenuItem>
                                          <MenuItem value="Long Response">Long Response</MenuItem>
                                          <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
                                          <MenuItem value="All Words That Apply">All Words That Apply</MenuItem>
                                          <MenuItem value="Numeric Rating">Numeric Rating</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Box>
                                    {
                                      (questionRecord.question_type === 'Multiple Choice' || questionRecord.question_type === 'All Words That Apply')
                                      &&
                                      <Box className='question-bontent' style={{ width: '97.5%', mb: 2 }}>
                                        <input type="text" id="myQuestion" placeholder="Enter comma separated options" tabIndex={(index * 2) + 4}
                                          value={questionRecord.questions}
                                          onChange={(event) => handleQuestionsFieldChange(questionRecord.id, event)} />
                                      </Box>
                                    }
                                    {
                                      (questionRecord.question_type === 'scale')
                                      &&
                                      <Box>
                                        <label className='rankings'>
                                          <span>Rank
                                            <CustomTooltip placement="right"
                                              title={
                                                <React.Fragment>Rank</React.Fragment>
                                              }>
                                              <em>i</em>
                                            </CustomTooltip>
                                          </span>
                                          <span>Commentary
                                            <CustomTooltip placement="right"
                                              title={
                                                <React.Fragment>Commentary</React.Fragment>
                                              }>
                                              <em>i</em>
                                            </CustomTooltip>
                                          </span>
                                        </label>
                                        <Box>
                                          {/* Rank and Commentary input fields... */}
                                          {
                                            questionRecord.rankings.map((data, index) => {
                                              return (
                                                <Box className="rankings-box" key={index + 1}>
                                                  <button
                                                    onClick={() => { console.log('remove button clicked') }}
                                                  >
                                                    <span style={{ color: "#333333" }}>{index + 1}</span>
                                                    <span style={{ fontSize: "48px" }}>-</span>
                                                  </button>
                                                  <input
                                                    type="text"
                                                    name="rank_commentary"
                                                    placeholder="Type here"
                                                    tabIndex={index * 2 + 2}
                                                  />
                                                </Box>
                                              );
                                            })
                                          }
                                          {/* add more button */}
                                          {/* <button
                                          className="rankings-button"
                                          onClick={() => { console.log('add more button clicked') }}
                                        >+</button> */}
                                        </Box>
                                      </Box>
                                    }

                                  </Box>
                                ))
                              }

                              {
                                questionsData.length !== addQuestionsLimit &&
                                <Button onClick={addQuestionTextFields} variant="contained" startIcon={<AddIcon />} className="crowdwave-blue">Add question</Button>
                              }
                            </Box>
                          </div>
                        </Container>
                      </Grid>
                      <Grid xs={12} md={12} lg={4}>
                        <RightPanel
                          audienceData={audienceData}
                          hasAudienceDataNonEmpty={hasAudienceDataNonEmpty}
                          segmentData={segmentData}
                          hasSegmentDataNonEmptyValue={hasSegmentDataNonEmptyValue}
                          questionsData={questionsData}
                          hasQuestionsDataNonEmptyValue={hasQuestionsDataNonEmptyValue}
                          selectSteps={selectSteps} activeTab={2} />
                      </Grid>

                    </Grid>
                  </div>

                  <Box style={{ display: activeStep === 3 ? 'block' : 'none' }}>
                    <Step3Component
                      audienceData={audienceData}
                      segmentData={segmentData}
                      questionsData={questionsData}
                      hasSegmentDataNonEmptyValue={hasSegmentDataNonEmptyValue}
                      selectSteps={selectSteps} />
                  </Box>

                  <Box className="btnblock" sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: (activeStep === steps.length - 1) ? '100%' : '66.6%' }}>
                    <Button variant="contained"
                      size={"large"}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      startIcon={<ArrowBackIosRoundedIcon />}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="contained" size={"large"} onClick={() => handleNext()} endIcon={<ArrowForwardIosRoundedIcon />}>
                      {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                    </Button>
                  </Box>

                </React.Fragment>
              )
          }
        </div>
      </Box>
    </div>
  );
}

export default withAuthenticationRequired(CreateSurvey, {
  onRedirecting: () => <Loading />,
});
